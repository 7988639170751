import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import react_logo from "../assets/react_logo.png";
import php_logo from "../assets/php_logo2.png";
import scriptcase_logo from "../assets/scriptcase2.png";
import python from "../assets/python_logo.png";
import nodejs from "../assets/node_logo.png";
import mongodb from "../assets/mongoDB.png";
import mySQL from "../assets/mySQL_logo.png";
import java from "../assets/java_logo.png";
import db2_logo from "../assets/DB2.png";
import sql_server_logo from "../assets/sql-server_logo.png";
import postgresSQL_logo from "../assets/postgresSQL_logo.png";
import mariaDB_logo from "../assets/mariaDB.png";
import flutter_logo from "../assets/flutter.png";
import html_logo from "../assets/html.png";
import css_logo from "../assets/css3.png";
import javascript_logo from "../assets/javascript_logo.png";
import genexus_logo from "../assets/genexus_logo.png";
import "../styles/pages/Technologies.css";

const Technologies = () => {
  return (
    <div className="tech_main-container">
      <Header/>
      <div className="tech_container">
        <h2 className="tech_main-h2">Technologies we work with:</h2>
        <h2 className="tech_h2">In Front<strong className="strong">end</strong></h2>
        <div className="tech_frontend-container">
          <div className="tech_img-card">
            <img className="tech_img-react_logo" src={react_logo} alt="react_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={php_logo} alt="php_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={flutter_logo} alt="php_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={html_logo} alt="html_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-css_logo" src={css_logo} alt="css_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={javascript_logo} alt="javascript_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={scriptcase_logo} alt="scriptcase_logo"/>
          </div>
        </div>
        <h2 className="tech_h2">For <strong className="strong">API</strong> development & Back<strong className="strong">end</strong></h2>
        <div className="tech_API-container">
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={java} alt="java_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-python_logo" src={python} alt="scriptcase_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={nodejs} alt="scriptcase_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={genexus_logo} alt="scriptcase_logo"/>
          </div>
        </div>
        <h2 className="tech_h2">Data<strong className="strong">bases</strong></h2>
        <div className="tech_backend-container">
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={mongodb} alt="scriptcase_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-mySQL_logo" src={mySQL} alt="scriptcase_logo"/>
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={db2_logo} alt="db2_logo" />
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={sql_server_logo} alt="sql_server_logo" />
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={postgresSQL_logo} alt="postgresSQL_logo" />
          </div>
          <div className="tech_img-card">
            <img className="tech_img-php_logo" src={mariaDB_logo} alt="mariaDB_logo" />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
export default Technologies;