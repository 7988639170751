import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/pages/Services.css"
import computer from "../assets/computer.gif";
import process from "../assets/presentation.gif";
import target from "../assets/target.gif";
import hacker from "../assets/hacker.gif";
import upload from "../assets/upload.gif";
import padlock from "../assets/padlock.gif";
import socialCare from "../assets/social-care.gif";
import warning from "../assets/warning.gif";
import techSupport from "../assets/tech-support.gif";
import protection from "../assets/protection.gif";
import document from "../assets/document.gif";
import search from "../assets/search.gif";
import network from "../assets/network.gif";

const Services = () => {
  return (
    <div className="services">
      <Header/>
      <section className="services__section">
        <h2 className="services-h2">What we <strong className="services-strong"> offer:</strong></h2>
        <h4 className="services-h4" >Comprehensive management of digital projects
        <strong className="services-strong-h4">(including web platforms and applications
             phones)</strong> from the first to the last step:</h4>
        <div className="about_page-cards-container">
          <div className="services-card">
            <strong className="services-strong"> First step</strong>
            <img src={computer} alt="computer" className="img"/>
              <p className="about_page-p">
                -1- Survey,
                 analysis and scope
              </p>
          </div>
          <div className="services-card">
            <strong className="services-strong"> Process</strong>
            <img src={process} alt="process" className="img"/>
              <p className="about_page-p">
                -2- Design
                 and development
              </p>
          </div>
          <div className="services-card">
            <strong className="services-strong"> Last step</strong>
            <img src={target} alt="computer" className="img"/>
              <p className="about_page-p">
                -3- Tests and
                 implementation
              </p>
          </div>
        </div>
        <h3 className="services-h3">Other <strong className="services-strong">services:</strong></h3>
        <div className="about_page-cards-container">
          <div className="services-card-support">
            <img src={techSupport} alt="techSupport" className="img"/>
              <p className="about_page-p">
                Services
                consulting
                computing
              </p>
          </div>
          <div className="services-card-support">
            <img src={hacker} alt="hacker" className="img"/>
              <p className="about_page-p">
                Skilled
                personnel
                join
                existing equipment
              </p>
          </div>
          <div className="services-card-support">
            <img src={padlock} alt="padlock" className="img"/>
              <p className="about_page-p">
                Developing
                on-demand,
                per project or
                contracted hours
              </p>
          </div>
          <div className="services-card-support">
            <img src={upload} alt="upload" className="img"/>
              <p className="about_page-p">
              Storage
              of files and/or
              platforms
              on the cloud
              </p>
          </div>
        </div>
        <h3 className="services-h3">Support <strong className="services-strong">Administration</strong></h3>
        <h4 className="services-h4">(from both existing platforms and those developed from scratch with <strong className="services-strong-h4">CEROUNO</strong>):</h4>
        <div className="about_page-cards-container">
          <div className="services-card-support">
            <img src={socialCare} alt="socialCare" className="img"/>
              <p className="about_page-p">
                User assistance
              </p>
          </div>
          <div className="services-card-support">
            <img src={warning} alt="warning" className="img"/>
              <p className="about_page-p">
                Incidences
              </p>
          </div>
          <div className="services-card-support">
            <img src={protection} alt="protection" className="img"/>
              <p className="about_page-p">
                Configuration settings
              </p>
          </div>
          <div className="services-card-support">
            <img src={document} alt="document" className="img"/>
              <p className="about_page-p">
                Update of versions
              </p>
          </div>
          <div className="services-card-support">
            <img src={search} alt="search" className="img"/>
              <p className="about_page-p">
                Integrity controls and data verifications at platform and database level
              </p>
          </div>
          <div className="services-card-support">
            <img src={network} alt="network" className="img"/>
              <p className="about_page-p">
                Replications and backups
              </p>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default Services;