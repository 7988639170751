import React from "react";
import "../styles/components/GetStarted.css";

const GetStarted = () => {
  return (
    <div className="getstarted__container" id="contactus">
      <div className="getstarted__form-container">
        <div className="getstarted__dot"></div>
        <div className="getstarted__dot2"></div>
        <div className="getstarted__dot6"></div>
        <form className="getstarted__form">
          <h2 className="getstarted__h2">Contact <strong className="getstarted__strong">us</strong></h2>
          <input className="getstarted__input" type="text" placeholder="Name"/>
          <input className="getstarted__input" type="email" placeholder="email"/>
          <input className="getstarted__input" type="text" placeholder="phone"/>
          <button className="getstarted__button" type="submit">Send</button>
        </form>
        <div className="getstarted__dot3"></div>
        <div className="getstarted__dot4"></div>
        <div className="getstarted__dot5"></div>
      </div>
    </div>
  );
}

export default GetStarted;