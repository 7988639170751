import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AboutPage from "./pages/AboutPage"
import Technologies from "./pages/Technologies"
import Services from './pages/Services';
import GetStarted from './components/GetStarted';
import ContactUs from './pages/ContactUs';


ReactDOM.render(
  <BrowserRouter>
    <Routes >
      <Route path='/' element={<App/>}/>
      <Route path='/AboutPage' element={<AboutPage/>}/>
      <Route path='/Services' element={<Services/>}/>
      <Route path='/Technologies' element={<Technologies/>}/>
      <Route path='/GetStarted' element={<GetStarted/>}/>
      <Route path='/ContactUs' element={<ContactUs/>}/>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

