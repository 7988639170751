import { Link } from "react-router-dom";
import "../styles/components/Hero.css"

const Hero = () => {
  return (
    <section className="hero__container">
    <div className="hero__container-headlines">
      <div className="hero__main__title">
        <h1 className="hero__container-h1">Creating </h1>
        <h1 className="hero__container-h1"> & </h1>
        <h2 className="hero__container-h2">innovating</h2>
      </div>
      <p className="hero_container-p">
        We create and innovate prioritizing agility and efficiency. We meet
        with the team, virtual and daily, as identify and overcome any
        obstacles, and comply with our weekly deliveries.
      </p>
      <Link to="/ContactUs">
        <button className="hero__container-btn">Get started</button>
      </Link>
    </div>
  </section>
  );
}

export default Hero;