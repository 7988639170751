import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/cerouno_logo.png";
import "../styles/components/Header.css"

const Header = () => {
  return (
    <header className="header">
    <div className="header__logo">
      <Link to="/">
        <img src={logo} alt="cerouno_logo" />
      </Link>
    </div>
    <div className="menu-btn">
      <div id="burguer-btn" className="menu-btn__burger"></div>
    </div>
    <ul className="header__items" id="header__items">
      <li className="header__items-li">
        <Link to="/aboutPage">About us</Link>
      </li>
      <li className="header__items-li">
        <Link to  ="/Technologies">Technologies</Link>
      </li>
      <li className="header__items-li">
        <Link to="/Services">Services</Link>
      </li>
      <Link className="header__btn" to="/ContactUs">
        <button>Contacto</button>
      </Link>
    </ul>
  </header>
  );
}

export default Header;