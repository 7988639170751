import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/Header";

const ContactUs = () => {
  return (
    <>
      <Header/>
      <GetStarted/>
      <Footer/>
    </>
  );
}

export default ContactUs;