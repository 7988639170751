import React from "react";
import "../styles/components/About.css";
import proyect5 from "../assets/proyectFive.jpeg";
import chat from "../assets/whitechat.png";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section className="about">
      <div className="about__img">
        <div className="about__background-circle">
          <img className="about_chat-img" src={proyect5} alt="about-img" />
          <img className="white_chat-img" src={chat} alt="white-chat-img" />
        </div>
      </div>
      <div className="about__text">
        <h2 className="about__text-h3">About us <span>&#160;</span></h2>
        <h3 className="about__text-h3">Come and get to know us <span>&#160;</span></h3>
        <p className="about__text-p">
          We were born in 2014 as a digital company, to become today a<span>&#160;</span>
          <strong className="about__text-strong"> laboratory of digital tools <span>&#160;</span></strong>
          that provides true
          <strong className="about__text-strong"> solutions for the daily work </strong>
          of our clients.<span>&#160;</span>
        </p>
        <Link  target="blank" to="AboutPage">
          <button>Read more</button>
        </Link>
      </div>
    </section>
  );
}

export default About;