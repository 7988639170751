import React from 'react';
import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import About from './components/About';
import Hero from './components/Hero';
// import Proyects from './components/Proyects';
// import Costumers from './components/Customers';
import GetStarted from './components/GetStarted';

function App() {
  return (
    <div className="App">
     <Header/>
     <Hero/>
     <About/>
     {/* <Proyects/>
     <Costumers/> */}
     <GetStarted/>
     <Footer/>
    </div>
  );
}

export default App;
