import React from "react";
import "../styles/components/Footer.css";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin-logo.png";
import instagram from "../assets/instagram_logo.png";
import whatsapp from "../assets/whatsapp.png";
import email from "../assets/email.png";
import logo from "../assets/cerouno_logo.png";

const Footer = () => {
  const whatsappText = "¡Hola Cerouno! Estoy interesado en...";
  return (
    <div className="footer__container">
      <footer className="footer">
        <span>© 2022 All rights reserved</span>
        <div className="footer__items">
          <img
            className="cerouno_logo"
            src={logo}
            alt="cerouno_logo"
          />
        </div>
        <div className="footer__social-media">
          <a href="https://www.instagram.com/cerouno.idi/" target="blank">
            <img
              className="footer__social-logo"
              src={instagram}
              alt="instagram_logo"
            />
          </a>
          <a href="https://www.linkedin.com/in/cerouno-labs-07a47410b/" target="blank">
            <img
              className="footer__social-logo"
              src={linkedin}
              alt="linkedin-logo"
            />
          </a>
          <a href="https://twitter.com/cerouno_idi" target="blank">
            <img
              className="footer__social-logo"
              src={twitter}
              alt="twitter-logo"
            />
          </a>
          <a href={`https://wa.me/595981060541?text=${encodeURIComponent(whatsappText)}`} target="blank" rel="noreferrer">
            <img
              className="footer__social-logo"
              src={whatsapp}
              alt="twitter-logo"
            />
          </a>
          <a href="mailto:hola@cerouno.com.py" target="blank">
            <img
              className="footer__social-logo"
              src={email}
              alt="twitter-logo"
            />
          </a>
        </div>
        {/* <span>Samaklay  c/s Corrales y Pitiantuta ( Fernando de la Mora)</span>
        <span>hola@cerouno.com.py</span>
        <span>(+595) 984 779436</span> */}
      </footer>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 300" className="waves">
        <path fill="#d7d7d7" fillOpacity="1" d="M0,96L48,96C96,96,192,96,288,128C384,160,480,224,576,229.3C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
        </path>
      </svg>
    </div>
  );
}

export default Footer;