import React from "react";
import "../styles/pages/AboutPage.css";
import Header from "../components/Header"
import thumpsup from "../assets/thumpsup.png";
import security from "../assets/security2.png";
import business from "../assets/business.png";
import incognito from "../assets/incognito.png";
import Footer from "../components/Footer";

const AboutPage = () => {
  return (
    <>
      <Header/>
      <section className="about_page">
        <div className="about_page-headlines">
          <h1 className="about_page-text-h1">About <strong className="about__text-strong">us</strong><span>&#160;</span></h1>
          <h2 className="about_page-text-h2">
              We were born in 2014 as a digital company, to become today a <strong className="about__text-strong">laboratory of digital tools</strong> that provides <strong className="about__text-strong">true solutions for the daily work</strong> of our clients.
              For us, it is extremely important that
              our client feel comfortable with our
              service at all times, and in every way.<br/>
              For this reason,
              <strong className="about__text-strong"> at CEROUNO we prioritize:</strong>
          </h2>
        </div>
        <div className="about_page-container">
          <div className="about_page-card">
            <strong className="about__text-strong"> THE SECURITY </strong>
            <img src={security} alt="security"/>
            <p className="about_page-p">
              The security of the data of
              both the client and the users.
            </p>
          </div>
          <div className="about_page-card">
            <strong className="about__text-strong"> THE EASE & RELIABILITY </strong>
            <img src={business} alt="business"/>
            <p className="about_page-p">
              The ease of use and reliability of the
              tools we develop
            </p>
          </div>
          <div className="about_page-card">
            <strong className="about__text-strong"> THE FLEXIBILITY</strong>
            <img src={thumpsup} alt="thumpsup"/>
            <p className="about_page-p">
              The flexibility of our process
              work that meets the needs of
              our clients.
            </p>
          </div>
          <div className="about_page-card">
          <strong className="about__text-strong"> THE CONFIDENCIALITY</strong>
          <img src={incognito} alt="incognito"/>
            <p className="about_page-p">
              The client's total confidence that his
              project is in the best hands.
            </p>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default AboutPage;